var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loader ? _c("loader") : _vm._e(),
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h2", [_vm._v(" " + _vm._s(this.$route.meta.title) + " ")]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-approved-credit-request-list" },
        [
          _c("custom-data-table", {
            attrs: {
              fields: _vm.fields,
              filters: _vm.filters,
              query: _vm.approved_credit_requests_query,
              "query-variables": { kind: "CREDIT_APPLYED", status: "APPROVED" },
              "query-node": "getTransactionOperationsByKindAndStatus",
            },
            scopedSlots: _vm._u([
              {
                key: "driver",
                fn: function (props) {
                  return _c(
                    "span",
                    {},
                    [
                      _c(
                        "router-link",
                        {
                          directives: [
                            { name: "b-tooltip", rawName: "v-b-tooltip" },
                          ],
                          attrs: {
                            title: _vm.$t("payments.labels.goToDriver"),
                            to: `/drivers/profile/${props.rowData.driver.id}`,
                          },
                        },
                        [
                          _vm._v(_vm._s(props.rowData.driver.name) + " "),
                          _c("i", {
                            staticClass: "fa fa-external-link",
                            staticStyle: {
                              width: "12px",
                              "text-align": "center",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "invoice",
                fn: function (props) {
                  return _c(
                    "span",
                    {},
                    [
                      _c(
                        "b-link",
                        {
                          attrs: {
                            href: _vm.kovi_fatura + props.rowData.invoice,
                            target: "_blank",
                            title: _vm.$t(
                              "payments.creditRequest.labelOpenInvoice"
                            ),
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-external-link",
                            staticStyle: {
                              width: "12px",
                              "text-align": "center",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("payments.creditRequest.goToInvoice")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                },
              },
              {
                key: "amount_operation",
                fn: function (props) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.setPriceFormat(
                            _vm.$t("payments.labels.currency"),
                            props.rowData.amount_operation
                          )
                        ) +
                        " "
                    ),
                  ])
                },
              },
              {
                key: "created_at",
                fn: function (props) {
                  return _c("span", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("moment")(
                            _vm.formatDateUtc(props.rowData.created_at),
                            "from",
                            true
                          )
                        ) +
                        " "
                    ),
                  ])
                },
              },
              {
                key: "approved_at",
                fn: function (props) {
                  return _c("span", {}, [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(
                          _vm.formatDateUtc(props.rowData.approved_at),
                          "from",
                          true
                        )
                      )
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }