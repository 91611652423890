<template>
  <div>
    <loader v-if="loader" />
    <b-row class="pb-4">
      <b-col cols="12">
        <h2>
          {{ this.$route.meta.title }}
        </h2>
      </b-col>
    </b-row>

    <div class="table-approved-credit-request-list">
    <custom-data-table
      :fields="fields"
      :filters="filters"
      :query="approved_credit_requests_query"
      :query-variables="{ kind: 'CREDIT_APPLYED', status: 'APPROVED' }"
      :query-node="'getTransactionOperationsByKindAndStatus'"
    >
      <span slot="driver" slot-scope="props"> <router-link v-b-tooltip :title="$t('payments.labels.goToDriver')" :to="`/drivers/profile/${props.rowData.driver.id}`">{{ props.rowData.driver.name }} <i class="fa fa-external-link" style="width: 12px; text-align: center" /></router-link></span>
      <span slot="invoice" slot-scope="props"><b-link
                :href="kovi_fatura + props.rowData.invoice"
                target="_blank"
                :title="$t('payments.creditRequest.labelOpenInvoice')"
                @click.stop
              >
                <i class="fa fa-external-link" style="width: 12px; text-align: center" />
                {{ $t('payments.creditRequest.goToInvoice') }}
              </b-link> </span>
      <span slot="amount_operation" slot-scope="props"> {{ setPriceFormat($t('payments.labels.currency'), props.rowData.amount_operation) }} </span>

      <span slot="created_at" slot-scope="props"> {{ formatDateUtc(props.rowData.created_at) | moment('from', true) }} </span>

      <span slot="approved_at" slot-scope="props">{{ formatDateUtc(props.rowData.approved_at) | moment('from', true) }}</span>
    </custom-data-table>
  </div>
  </div>
</template>

<script>
import { formatPrice } from '@utils/numbers';
import CustomDataTable from '@components/CustomDataTable';
import TRANSACTION_OPERATIONS_BY_KIND_STATUS from '@graphql/transaction-operations/queries/list-by-kind-and-status.gql';

export default {
  name: 'CreditRequestsByStatus',
  components: {
    CustomDataTable,
    Loader: () => import('@components/shared/loader')
  },
  props: {
    fieldsAllow: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      loader: false,
      approved_credit_requests_query: TRANSACTION_OPERATIONS_BY_KIND_STATUS,
      kovi_fatura: process.env.KOVI_FATURA_URL,
      status: 'ACTIVE',
      fields: [{
          name: 'driver',
          title: this.$t('payments.creditRequest.tableDriverFieldTitle'),
        },
        {
          name: 'reason',
          title: this.$t('payments.creditRequest.tableReasonFieldTitle'),
        },
        {
          name: 'amount_operation',
          title: this.$t('payments.creditRequest.tableAmountFieldTitle'),
        },
        {
          name: 'approved_by',
          title: this.$t('payments.creditRequest.tableApprovedByFieldTitle'),
        },
        {
          name: 'created_at',
          title: this.$t('payments.creditRequest.tableCreatedAtFieldTitle'),
          sortField: 'created_at'
        },
        {
          name: 'approved_at',
          title: this.$t('payments.creditRequest.tableApprovedAtFieldTitle'),
          sortField: 'approved_at'
        }
      ],
      pagination: {
        current: 1,
        total: 10,
        order: 'DESC',
      },
    }
  },
  computed: {
    filters() {
      return {}
    },
  },
  methods: {
    formatDateUtc (date) {
      return this.$moment.utc(date).format('YYYY-MM-DDTHH:mm:ss')
    },
    setPriceFormat(_currency, _value) {
      return formatPrice(_currency, _value);
    },
  },
};
</script>

<style scoped>
.custom-data-table__pagination {
  margin-bottom: 40px;
}
</style>